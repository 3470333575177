<template>
  <div>
    <v-text-field
      v-model="videoMetadata.title"
      type="text"
      label="タイトル"
      placeholder="タイトルを入力してください"
      class="my-4"
      :error-messages="errorMessage.title"
      required
      outlined
    />

    <v-select
      v-model="videoMetadata.categories"
      label="カテゴリ"
      :items="categories"
      item-text="name"
      item-value="id"
      multiple
      chips
      outlined
    >
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          :color="item.settings.pin_color"
          class="white--text pa-6"
        >
          <v-icon left>mdi-shape-outline</v-icon>
          {{ item.name }}
        </v-chip>
      </template>
    </v-select>

    <v-textarea
      v-model="videoMetadata.description"
      label="動画の説明"
      placeholder="動画の説明文を入力してください"
      class="my-4"
      :error-messages="errorMessage.description"
      outlined
    />

    <v-textarea
      v-model="videoMetadata.memo"
      label="メモ"
      placeholder="管理画面のみで表示されるメモです。公開画面には表示されません。"
      class="my-4"
      :error-messages="errorMessage.memo"
      outlined
    />

    <v-text-field
      v-model="videoMetadata.photographer"
      type="text"
      label="撮影者"
      placeholder="撮影者、組織などを入力してください"
      class="my-4"
      :error-messages="errorMessage.photographer"
      outlined
    />

    <v-text-field
      v-model="videoMetadata.owner"
      type="text"
      label="権利者"
      placeholder="権利者、組織などを入力してください"
      class="my-4"
      :error-messages="errorMessage.owner"
      outlined
    />

    <v-textarea
      v-model="videoMetadata.place"
      label="撮影場所"
      placeholder="撮影場所の地名や施設名を入力してください"
      class="my-4"
      :error-messages="errorMessage.place"
      outlined
    />

    <!-- Google 緯度経度-->
    <VideoNewFormLocation
      :latitude="videoMetadata.latitude"
      :longitude="videoMetadata.longitude"
      :error-lat="errorMessage.latitude"
      :error-lng="errorMessage.longitude"
      @save-lat="saveLat"
      @save-lng="saveLng"
      @use-google-maps="
        isUsedGoogleMaps = true;
        videoMetadata.isEnabledLocationOD = false;
      "
      @use-osm="isUsedGoogleMaps = false"
    />

    <v-menu
      v-model="isOpenedDate"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          autocomplete="off"
          label="撮影日"
          placeholder="撮影日を入力してください"
          :error-messages="errorMessage.date"
          hint="※ キーボードで入力する場合は半角で入力してください。例）2020-01-01"
          persistent-hint
          clearable
          outlined
          v-bind="attrs"
          @focus="errorMessage.date = ''"
          v-on="on"
          @click:clear="dateClear()"
        />
      </template>
      <v-date-picker
        v-model="date"
        locale="jp-ja"
        :day-format="(date) => new Date(date).getDate()"
        @change="isOpenedDate = false"
      />
    </v-menu>

    <v-menu
      ref="menu"
      v-model="isOpenedTime"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="time"
          autocomplete="off"
          label="撮影時刻"
          placeholder="撮影時刻を入力してください"
          :error-messages="errorMessage.time"
          hint="※ キーボードで入力する場合は半角で入力してください。例）12:00"
          persistent-hint
          clearable
          outlined
          v-bind="attrs"
          @focus="errorMessage.time = ''"
          v-on="on"
          @click:clear="timeClear()"
        />
      </template>
      <v-time-picker
        v-if="isOpenedTime"
        v-model="time"
        @click:minute="$refs.menu.save(time)"
      />
    </v-menu>

    <p>{{ dateStr }} {{ timeStr }}</p>

    <v-select
      v-model="license"
      label="ライセンスを選択してください"
      :items="licenses"
      item-text="label"
      item-value="value"
      class="my-4"
      prepend-icon="mdi-information-outline"
      :error-messages="errorMessage.license"
      outlined
      @click:prepend="openLicensesPage"
    />

    <v-text-field
      v-if="videoMetadata.license === 'etc'"
      v-model="videoMetadata.licenseDescription"
      type="text"
      label="ライセンス自由入力"
      placeholder="選択肢がない場合のライセンスを入力してください"
      class="my-4"
      :error-messages="errorMessage.licenseDescription"
      outlined
    />

    <v-combobox
      v-model="choosedTags"
      label="タグ"
      :items="tags"
      item-text="label"
      class="my-4"
      multiple
      chips
      outlined
    >
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          close
          @click:close="removeTag(item)"
        >
          {{ item.label }}
        </v-chip>
      </template>
    </v-combobox>

    <v-select
      v-model="videoMetadata.status"
      label="ステータス"
      :items="statuses"
      item-text="label"
      item-value="value"
      class="my-4"
      :error-messages="errorMessage.status"
      outlined
    />

    <hr class="mt-8 mb-4" />

    <v-checkbox
      v-model="videoMetadata.isEnabledLocationOD"
      label="緯度と経度をオープンデータとして公開する"
      hide-details
      :disabled="isUsedGoogleMaps"
      prepend-icon="mdi-information-outline"
      class="my-4"
      @click:prepend="isShowedNoteDialog = true"
    />

    <v-row v-if="$route.meta === 'new'" class="my-4" justify="end">
      <v-btn color="primary" large @click="save">登録</v-btn>
    </v-row>
    <v-row v-if="$route.meta === 'edit'" class="my-4" justify="space-between">
      <v-btn color="red" large outlined @click="isShowedRemoveDialog = true"
        >削除する</v-btn
      >
      <v-btn color="primary" large @click="save">上書き保存</v-btn>
    </v-row>

    <v-dialog
      v-model="isShowedNoteDialog"
      max-width="400"
      :style="{ zIndex: 1000 }"
    >
      <v-card class="py-12 px-10">
        <v-card-text>
          Google Mapsで取得した緯度経度は、Google
          Mapsのライセンス上、オープンデータとして公開することができません。緯度経度をオープンデータにする場合には、これらがOpenStreetMapなどの他の手法で取得した緯度経度であることを確認してください。
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isShowedNoteDialog = false">
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ModalConfirmation
      :state-modal="isShowedRemoveDialog"
      title="削除してもよろしいですか?"
      color="red"
      label="削除"
      @hideModal="isShowedRemoveDialog = false"
      @apply="remove"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import dayjsUtc from "dayjs/plugin/utc";
import dayjsTimezone from "dayjs/plugin/timezone";
import dayjsCustomParseFormat from "dayjs/plugin/customParseFormat";
import { getData, createData, deleteData } from "../axios";
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsCustomParseFormat);

import store from "@/store";

import VideoNewFormLocation from "./VideoNewFormLocation.vue";
import ModalConfirmation from "./ModalConfirmation.vue";

export default {
  name: "VideoNewForm",
  components: {
    VideoNewFormLocation,
    ModalConfirmation,
  },
  props: {
    licensesPageUrl: String,
    licenses: Array,
    statuses: Array,
    video: [Object, File],
  },
  data() {
    return {
      isOpenedDate: false,
      dateStr: "",
      isOpenedTime: false,
      timeStr: "",
      videoMetadata: {},
      tags: [],
      categories: [],
      isUsedGoogleMaps: false,
      isShowedNoteDialog: false,
      isShowedRemoveDialog: false,
      errorMessage: {
        date: null,
        time: null,
        title: null,
        license: null,
        video: null,
        status: null,
        photographer: null,
        owner: null,
        licenseDescription: null,
        description: null,
        memo: null,
        place: null,
        latitude: null,
        longitude: null,
      },
      fieldNames: {},
      newRequired: ["title", "license", "video", "status"],
      editRequired: ["title", "license", "status"],
    };
  },
  async beforeRouteEnter(_to, _from, next) {
    await next(async (vm) => {
      await vm.init();
    });
  },
  async beforeRouteUpdate(_to, _from, next) {
    await next(async (vm) => {
      await vm.init();
    });
  },
  computed: {
    date: {
      get: function () {
        return this.dateStr;
      },
      set: function (value) {
        if (!value && this.timeStr) {
          this.dateStr = "";
          this.errorMessage.date =
            "撮影時刻だけを指定することはできません。日付も指定してください。";
          return;
        } else if (!value) {
          this.dateStr = "";
          this.errorMessage.date = null;
          return;
        }

        const valueObj = dayjs(value.trim(), "YYYY-MM-DD", true);
        if (!valueObj.isValid()) {
          this.errorMessage.date = "撮影日が不正な値です。";
          return;
        }
        this.errorMessage.date = null;

        this.dateStr = valueObj.format("YYYY-MM-DD");
      },
    },
    time: {
      get: function () {
        return this.timeStr;
      },
      set: function (value) {
        if (!value) {
          this.timeStr = "";
          this.errorMessage.time = null;
          if (!this.dateStr) {
            this.errorMessage.date = null;
          }
          return;
        }

        const valueObj = dayjs(value.trim(), "HH:mm", true);
        if (!valueObj.isValid()) {
          this.errorMessage.time = "撮影時刻が不正な値です。";
          return;
        } else if (!this.dateStr) {
          // 日付指定がなければ、時間指定不可
          this.errorMessage.time = "先に撮影日を指定してください。";
          return;
        }
        this.errorMessage.time = null;

        this.timeStr = valueObj.format("HH:mm");
      },
    },
    license: {
      get: function () {
        return this.videoMetadata.license;
      },
      set: function (value) {
        if (value.match("nd")) {
          this.$store.dispatch("snackbar/setSnackbar", {
            message: "オープンなCCライセンスではありません",
            color: "error",
            timeout: 4000,
          });
        }

        this.videoMetadata.license = value;
      },
    },
    choosedTags: {
      get: function () {
        return this.videoMetadata.tags;
      },
      set: function (value) {
        this.videoMetadata.tags = value.map((v) => {
          if (typeof v === "string") {
            return {
              label: v,
            };
          } else {
            return v;
          }
        });
      },
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.videoMetadata = {
        title: "",
        categories: [],
        description: "",
        memo: "",
        photographer: "",
        owner: "",
        place: "",
        locationAccuracy: "unknown",
        latitude: 41.7829738,
        longitude: 140.7504901,
        license: "",
        licenseDescription: "",
        tags: [],
        status: "unpublished",
        isEnabledLocationOD: true,
        shotAtAccuracy: "unknown",
        shotAt: null,
      };
      this.fieldNames = {
        title: "タイトル",
        description: "動画の説明",
        memo: "メモ",
        photographer: "撮影者",
        owner: "権利者",
        place: "撮影場所",
        latitude: "撮影場所の緯度",
        longitude: "撮影場所の経度",
        shot_at: "撮影日時",
        license: "ライセンス",
        license_description: "ライセンス自由入力",
        image: "動画",
        status: "ステータス",
      };

      await this.getCategories();
      await this.getTags();
      if (this.$route.meta === "edit") {
        await this.getVideo(this.$route.params.videoId);
      }
    },
    saveLat(value) {
      this.videoMetadata.latitude = value;
    },
    saveLng(value) {
      this.videoMetadata.longitude = value;
    },
    dateClear() {
      this.date = "";
    },
    timeClear() {
      this.time = "";
    },
    openLicensesPage(e) {
      e.preventDefault();
      window.open(this.licensesPageUrl);
    },
    removeTag(item) {
      this.videoMetadata.tags = this.videoMetadata.tags.filter(
        (t) => t !== item
      );
    },
    async save() {
      if (!this.validate()) {
        // バリデーションをpassできない
        this.$nextTick(() => {
          // エラーメッセージのある入力欄までスクロール
          const errorElement = document.querySelector(".error--text");
          if (errorElement) {
            errorElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        });
        return;
      }

      // Loading表示
      let loadingMessage;
      if (this.$route.meta === "edit") {
        loadingMessage = "更新中...";
      } else {
        loadingMessage = "作成中...";
      }
      this.$emit("tap-loading-modal", true, loadingMessage);

      this.setShotAt();
      await this.saveNewTag();

      // Create Video
      const videoPost = JSON.parse(JSON.stringify(this.videoMetadata));
      videoPost.tags = videoPost.tags.map((tag) => tag.id);

      // camelCase to snake_case
      [
        ["clientId", "client_id"],
        ["imageWidth", "image_width"],
        ["imageHeight", "image_height"],
        ["locationAccuracy", "location_accuracy"],
        ["licenseDescription", "license_description"],
        ["isEnabledLocationOD", "enable_location_opendata"],
        ["shotAtAccuracy", "shot_at_accuracy"],
        ["shotAt", "shot_at"],
        ["videoReadyToStream", "video_ready_to_stream"],
        ["videoTokenExpiresAt", "video_token_expires_at"],
        ["videoToken", "video_token"],
        ["videoUrl", "video_url"],
        ["videoName", "video_name"],
      ].forEach((f) => {
        if (f[0] in videoPost === false) {
          return;
        }
        videoPost[f[1]] = videoPost[f[0]];
        delete videoPost[f[0]];
      });

      if (this.video.lastModified) {
        videoPost.is_wanted_video_upload_url = true;
      } else {
        videoPost.is_wanted_video_upload_url = false;
      }

      let resVideo;
      if (this.$route.meta === "edit") {
        resVideo = await fetch(
          `${process.env.VUE_APP_API_URL}/api/admin/videos/${this.$route.params.videoId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${store.getters["auth/token"]}`,
            },
            body: JSON.stringify(videoPost),
          }
        ).catch((e) => {
          console.log(e);
        });
      } else {
        resVideo = await fetch(
          `${process.env.VUE_APP_API_URL}/api/admin/videos`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${store.getters["auth/token"]}`,
            },
            body: JSON.stringify(videoPost),
          }
        ).catch((e) => {
          console.log(e);
        });
      }

      if (!resVideo.ok) {
        console.error("ERRORRRRRR");
        return;
      }

      const data = await resVideo.json();
      if (!data.video_upload_url) {
        this.$emit("tap-loading-modal", false, "");
        this.$store.dispatch("snackbar/setSnackbar", {
          message: `「${data.title}」を更新しました。`,
          color: "success",
          timeout: 2000,
        });
        this.$router.push({ name: "video_index" });
        return;
      }
      this.$emit("upload-video-file", data);
    },
    setShotAt() {
      if (!this.dateStr) {
        this.videoMetadata.shotAt = null;
        return;
      }

      const dateObj = dayjs(this.dateStr, "YYYY-MM-DD", true).tz("Asia/Tokyo");
      if (this.timeStr) {
        const timeObj = dayjs(this.timeStr, "HH:mm", true);

        this.videoMetadata.shotAt = dateObj
          .hour(timeObj.hour())
          .minute(timeObj.minute())
          .toISOString();
        //.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      } else {
        this.videoMetadata.shotAt = dateObj.toISOString(); //.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      }
    },
    async saveNewTag() {
      const newTags = this.videoMetadata.tags
        .filter((v) => !v.slug)
        .map((nv) => nv.label);
      if (newTags.length === 0) {
        return;
      }

      const res = await createData("tags/bulk", { tags: newTags });
      this.videoMetadata.tags = this.videoMetadata.tags.map((t) => {
        if (!t.slug) {
          return res.data.find((datum) => datum.label === t.label);
        }
        return t;
      });
    },
    async remove() {
      this.$emit("tap-loading-modal", true, "削除中...");
      this.isShowedRemoveDialog = false;
      await deleteData(`videos/${this.$route.params.videoId}`);

      this.$emit("tap-loading-modal", false, "");
      this.$store.dispatch("snackbar/setSnackbar", {
        message: `「${this.videoMetadata.title}」を削除しました。`,
        color: "success",
        timeout: 2000,
      });
      this.$router.push({ name: "photos" });
    },
    validate() {
      Object.keys(this.errorMessage).forEach((key) => {
        if (["date", "time"].includes(key)) return;
        this.errorMessage[key] = null;
      });
      this.checkRequired(
        this.$route.meta === "new" ? this.newRequired : this.editRequired
      );
      this.checkMaxLength(100, [
        "title",
        "photographer",
        "owner",
        "licenseDescription",
      ]);
      this.checkMaxLength(1000, ["description", "memo", "place"]);
      this.checkNumber(["latitude", "longitude"]);
      this.checkVideo();

      return Object.values(this.errorMessage).every((em) => em === null);
    },
    checkVideo() {
      if (this.video) {
        return;
      }
      this.errorMessage.video = true;
      this.$emit("check-video", true);
    },
    checkRequired(targets) {
      for (const target of targets) {
        if (
          this.videoMetadata[target] &&
          this.videoMetadata[target].length > 0
        ) {
          return;
        }

        this.errorMessage[
          target
        ] = `${this.fieldNames[target]}は、必須項目です。`;
      }
    },
    checkMaxLength(max, targets) {
      for (const target of targets) {
        if (!this.videoMetadata[target]) {
          return;
        } else if (
          this.videoMetadata[target] &&
          this.videoMetadata[target].length <= max
        ) {
          return;
        }

        this.errorMessage[
          target
        ] = `${this.fieldNames[target]}は、${max}文字以下で記入してください。`;
      }
    },
    checkNumber(targets) {
      for (const target of targets) {
        if (
          this.videoMetadata[target] &&
          Number.isFinite(Number(this.videoMetadata[target]))
        ) {
          return;
        }

        this.errorMessage[
          target
        ] = `${this.fieldNames[target]}は、半角数字で記入してください。`;
      }
    },
    async getVideo(videoId) {
      const res = await getData(`videos/${videoId}`);
      this.videoMetadata = res.data;

      [
        ["client_id", "clientId"],
        ["image_width", "imageWidth"],
        ["image_height", "imageHeight"],
        ["location_accuracy", "locationAccuracy"],
        ["license_description", "licenseDescription"],
        ["enable_location_opendata", "isEnabledLocationOD"],
        ["view_count", "viewCount"],
        ["shot_at_accuracy", "shotAtAccuracy"],
        ["shot_at", "shotAt"],
        ["video_ready_to_stream", "videoReadyToStream"],
        ["video_token_expires_at", "videoTokenExpiresAt"],
        ["view_count_updated_at", "viewCountUpdatedAt"],
        ["video_token", "videoToken"],
        ["video_url", "videoUrl"],
        ["video_name", "videoName"],
      ].forEach((f) => {
        if (f[0] in this.videoMetadata === false) {
          return;
        }

        this.videoMetadata[f[1]] = this.videoMetadata[f[0]];
        delete this.videoMetadata[f[0]];
      });

      if (this.videoMetadata.shotAt) {
        const shotAtObj = dayjs(this.videoMetadata.shotAt);
        this.dateStr = shotAtObj.format("YYYY-MM-DD");
        this.timeStr = shotAtObj.format("HH:mm");
      }

      this.videoMetadata.categories = this.videoMetadata.categories.map(
        (c) => c.id
      );

      // すでにアップロードされているため、プレビューを見せたい。
      this.$emit(
        "show-preview",
        this.videoMetadata.videoName,
        this.videoMetadata.videoUrl
      );
    },
    async getCategories() {
      const res = await getData("categories");
      this.categories = res.data;
    },
    async getTags() {
      const res = await getData("tags");
      this.tags = res.data;
    },
  },
};
</script>
